
import { defineComponent, computed, inject, ref, onMounted } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Socket } from "socket.io-client";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    KTNotificationsMenu,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const Switcher = ref(store.getters.teacherSwitcherValue);
    const notifications = ref(0);
    var titleLoop;
    let test = true;
    const ptcount = ref(0);
    let ptest = true;
    var ptitleLoop;

    const teacherToken = JwtService.getToken();
    const currentBuilding = store.getters.serverConfigUrl.dbName;
    const currentUserId = ref(store.getters.currentUser._id);

    const socket: Socket = inject("socket") as Socket;

    socket.emit("teacherJoin", {
      teacherId: currentUserId,
      token: teacherToken,
      building: currentBuilding,
    });

    socket.on("pt-messageNotification", () => {
      if (store.getters.hasTeacherAccess) {
        ptcount.value++;
        newPTNotify();
        const audio = new Audio("/media/notification.mp3");
        audio.play();
      }
    });

    const togglePTChat = () => {
      ptcount.value = 0;
      resetPTNotification();
    };

    socket.on("teacherJoined", () => {
      ApiService.get(`/pt-chat/teacher/unseenMessages`).then(({ data }) => {
        ptcount.value = 0;
        data.forEach((d) => {
          ptcount.value += Number(d.count);
        });
      });
    });

    const resetPTNotification = () => {
      clearInterval(titleLoop);
      document.title = "EdTrust | Contrôle parental";
    };

    const newPTNotify = () => {
      clearInterval(ptitleLoop);
      titleLoop = setInterval(() => {
        if (ptest) document.title = "EdTrust | Contrôle parental";
        else document.title = "Nouveaux messages";
        ptest = !ptest;
      }, 1500);
    };

    socket.on("newToValidation", (data) => {
      if (
        store.getters.hasAdvisorAccess &&
        data.userType == "advisor" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("StudentPunishmentRejected", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newSubmitedHomework", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newValidatedHomework", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newValidatedCourse", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newMeeting", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newResearch", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newActuality", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newComment", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    socket.on("newEvent", (data) => {
      if (
        store.getters.hasTeacherAccess &&
        data.userType == "teacher" &&
        data.users.includes(store.getters.currentUser._id)
      ) {
        newNotify(data.topic);
      }
    });

    const getNotifications = async () => {
      try {
        const { data } = await ApiService.get("/notifications");
        notifications.value = data.unreadCount;
      } catch (error) {
        console.error("Failed to load notifications:", error);
      }
    };

    const newNotify = (topic: string) => {
      notifications.value++;
      clearInterval(titleLoop);
      titleLoop = setInterval(() => {
        if (test) document.title = "EdTrust";
        else document.title = "(" + notifications.value + ") " + topic;
        test = !test;
      }, 1500);
    };

    const resetNotification = async () => {
      try {
        await ApiService.post("/notifications/mark-as-read", {});
        notifications.value = 0;
        clearInterval(titleLoop);
        document.title = "EdTrust";
      } catch (error) {
        console.error("Failed to mark notifications as read:", error);
      }
    };

    const handleSwitch = () => {
      if (Switcher.value) {
        store.commit("SWITCH_TO_TEACHER");
      } else {
        store.commit("SWITCH_TO_ADVISOR");
      }
      router.push({ path: "/dashboard" });
      setTimeout(async () => {
        router.go(0);
      }, 300);
    };

    onMounted(() => {
      getNotifications();
    });

    const currentPhoto = computed(() => store.getters.userPhotoURL);
    return {
      currentPhoto,
      notifications,
      resetNotification,
      t,
      togglePTChat,
      ptcount,
      store,
      handleSwitch,
      Switcher,
      canSwitchToTeacher: computed(() => store.getters.canSwitchToTeacherValue),
      buildingConfigValue: computed(() => store.getters.buildingConfigValue),
    };
  },
});
